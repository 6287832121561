import { Disclosure, Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./Icon";
import { l } from "./languageUtils";
import { navigate } from "astro:transitions/client";
import ExternalLinksModal from "./externalLinksModal";

import SobiImage from "../assets/images/sobi_logo.png";
import EmpaveliLogo from "../assets/images/empaveliLogo.png";

interface FooterProps {
  lang: "en" | "CA-fr";
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ lang, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  const openModal = (e: React.MouseEvent, link: string) => {
    console.log("openModal");
    e.preventDefault();
    setIsOpen(true);
    setLink(link);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openLink = () => {
    if (link) {
      window.open(link, "_blank");
      setLink(null);
      setIsOpen(false);
    }
  };

  return (
    <footer className={twMerge(`text-[#595959] max-w-7xl`, className)}>
      <div>
        {lang != "CA-fr" ? (
          <>
            <p className="pb-5 text-sm">
              Please consult the EMPAVELI Product Monograph at{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.sobi.ca/EMPAVELI_PM_EN"
                className="underline font-bold"
              >
                sobi.ca/EMPAVELI_PM_EN
              </ExternalLinksModal>{" "}
              for important information relating to contraindications, serious warnings and precautions, other warnings and precautions, adverse reactions, interactions, dosing, and conditions of clinical use.
            </p>

            <p className="pb-5 text-sm">
              The Product Monograph is also available by calling our medical department at {" "}
              <a target="_blank" className="underline" href="tel:1-866‑773‑5274">
                1-866‑773‑5274
              </a>
              .
            </p>
          </>
        ) : (
          <>
            <p className="pb-5 text-sm">
              Veuillez consulter la monographie de produit d'EMPAVELI à l'adresse{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.sobi.ca/EMPAVELI_PM_FR"
                className="underline font-bold"
              >
                sobi.ca/EMPAVELI_PM_FR
              </ExternalLinksModal>{" "}
              pour obtenir des renseignements importants sur les contre-indications, les mises en garde, les
              précautions, les effets indésirables, les interactions, la posologie et les conditions d'utilisation
              clinique.
            </p>

            <p className="pb-5 text-sm">
              La monographie de produit est également accessible en appelant notre service médical au&nbsp;:{" "}
              <a target="_blank" className="underline" href="tel:1-866‑773‑5274">
                1 866‑773‑5274
              </a>
              .
            </p>
          </>
        )}

        {/* this is the mobile version of the code*/}
        <div className="block md:hidden">
          <div className="mb-4">
            {lang != "CA-fr" ? (
              <p>
                <span className="font-bold">EAP:</span> Exceptional access program
              </p>
            ) : (
              <p>
                <span className="font-bold">PAE:</span> Programme d'accès exceptionnel
              </p>
            )}
            {lang != "CA-fr" ? (
              <p>
                <span className="font-bold">EDRD:</span> Expensive drugs for rare diseases
              </p>
            ) : (
              <p>
                <span className="font-bold">MOMR:</span> Médicament onéreux pour le traitement de maladies rares
              </p>
            )}
          </div>
          {lang != "CA-fr" ? (
            <p className="pb-5">
              <span className="font-bold">REFERENCES: 1.</span> EMPAVELI Product Monograph, Swedish Orphan Biovitrum AB
              (publ), Inc. December 7, 2022. <span className="font-bold">2.</span> Indigenous Services Canada.
              Non-Insured Health Benefits; First Nations and Inuit Health Branch. Accessed March 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://nihb.express-scripts.ca/NIHBProvider/benefits/pharmacy?page=drugbenefit-grid&benefit=pharmacy"
              >
                <span>
                  https://nihb.express-scripts.ca/NIHBProvider/benefits/pharmacy?page=drugbenefit-grid&benefit=pharmacy
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">3.</span> Veterans Affairs Canada. Coverage for services, prescriptions and
              devices. Accessed April 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.veterans.gc.ca/en/financial-programs-and-services/medical-costs/coverage-services-prescriptions-and-devices"
              >
                <span>
                  https://www.veterans.gc.ca/en/financial-programs-and-services/medical-costs/coverage-services-prescriptions-and-devices
                </span>
              </ExternalLinksModal>
              .
            </p>
          ) : (
            <p className="pb-5">
              <span className="font-bold">RÉFÉRENCES: 1. </span>Monographie de produit d'EMPAVELI, Swedish Orphan
              Biovitrum AB (publ), Inc. 7 décembre 2022. <span className="font-bold">2. </span>Services aux Autochtones
              Canada. Services de santé non assurés; Direction générale de la santé des Premières Nations et des Inuits.
              Consulté en mars 2024 à l'adresse suivante&nbsp;:{" "}
              <ExternalLinksModal lang={lang} externalLink="https://nihb-ssna.express-scripts.ca/fr">
                <span>https://nihb-ssna.express-scripts.ca/fr</span>
              </ExternalLinksModal>
              . <span className="font-bold">3. </span>Anciens combattants Canada Couverture pour services, ordonnances
              et appareils. Consulté en avril 2024 à l'adresse suivante&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.veterans.gc.ca/fr/programmes-et-services-financiers/frais-medicaux/couverture-pour-services-ordonnances-et-appareils"
              >
                <span>
                  https://www.veterans.gc.ca/fr/programmes-et-services-financiers/frais-medicaux/couverture-pour-services-ordonnances-et-appareils
                </span>
              </ExternalLinksModal>
              .
            </p>
          )}
        </div>

        {/* this is the desktop version of the code */}
        <div className="hidden md:block">
          <div className="mb-4">
            {lang != "CA-fr" ? (
              <p>
                <span className="font-bold">EAP:</span> Exceptional access program
              </p>
            ) : (
              <p>
                <span className="font-bold">PAE:</span> Programme d'accès exceptionnel
              </p>
            )}
            {lang != "CA-fr" ? (
              <p>
                <span className="font-bold">EDRD:</span> Expensive drugs for rare diseases
              </p>
            ) : (
              <p>
                <span className="font-bold">MOMR:</span> Médicament onéreux pour le traitement de maladies rares
              </p>
            )}
          </div>
          {lang != "CA-fr" ? (
            <p className="pb-5">
              <span className="font-bold">REFERENCES: 1.</span> EMPAVELI Product Monograph, Swedish Orphan Biovitrum AB
              (publ), Inc. December 7, 2022. <span className="font-bold">2.</span> Government of British Columbia. Drug
              coverage decision for BC Pharmacare. Accessed March 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www2.gov.bc.ca/assets/gov/health/health-drug-coverage/pharmacare/decisions/1280145_-pegcetacoplan_dds.pdf"
              >
                <span>
                  https://www2.gov.bc.ca/assets/gov/health/health-drug-coverage/pharmacare/decisions/1280145_-pegcetacoplan_dds.pdf
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">3.</span> Alberta Health. Interactive drug benefit list. Accessed March 2024
              at&nbsp;:{" "}
              <ExternalLinksModal lang={lang} externalLink="https://idbl.ab.bluecross.ca/idbl/load.do">
                <span>https://idbl.ab.bluecross.ca/idbl/load.do</span>
              </ExternalLinksModal>
              . <span className="font-bold">4.</span> RAMQ. List of Medications. Accessed March 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-03-06_en.pdf"
              >
                <span>
                  https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-03-06_en.pdf
                </span>
              </ExternalLinksModal>
              . Official Mark of the Régie de l'assurance maladie du Québec. <span className="font-bold">5.</span>{" "}
              Indigenous Services Canada. Non-Insured Health Benefits; First Nations and Inuit Health Branch. Accessed
              March 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://nihb.express-scripts.ca/NIHBProvider/benefits/pharmacy?page=drugbenefit-grid&benefit=pharmacy"
              >
                <span>
                  https://nihb.express-scripts.ca/NIHBProvider/benefits/pharmacy?page=drugbenefit-grid&benefit=pharmacy
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">6.</span> Veterans Affairs Canada. Coverage for services, prescriptions and
              devices. Accessed April 2024 at&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.veterans.gc.ca/en/financial-programs-and-services/medical-costs/coverage-services-prescriptions-and-devices"
              >
                <span>
                  https://www.veterans.gc.ca/en/financial-programs-and-services/medical-costs/coverage-services-prescriptions-and-devices
                </span>
              </ExternalLinksModal>
              .
            </p>
          ) : (
            <p className="pb-5">
              <span className="font-bold">RÉFÉRENCES: 1. </span>Monographie de produit d'EMPAVELI, Swedish Orphan
              Biovitrum AB (publ), Inc. 7 décembre 2022. <span className="font-bold">2. </span>Gouvernement de la
              Colombie-Britannique Décision relative au remboursement des médicaments dans le cadre de BC Pharmacare.
              Consulté en mars 2024 à l'adresse suivante&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/pharmacare-for-bc-residents/what-we-cover/exceptional-funding-edrd"
              >
                <span>
                  https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/pharmacare-for-bc-residents/what-we-cover/exceptional-funding-edrd
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">3. </span>Alberta Health. Liste interactive des médicaments couverts.
              Consulté en mars 2024 à l'adresse suivante&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://idbl.ab.bluecross.ca/idbl/drugsList?_cid=35152abb-7558-4f86-b0dd-4c477006d9d5&searchTerm=empaveli"
              >
                <span>
                  https://idbl.ab.bluecross.ca/idbl/drugsList?_cid=35152abb-7558-4f86-b0dd-4c477006d9d5&searchTerm=empaveli
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">4. </span>RAMQ. Liste des médicaments. Consulté en mars 2024 à l'adresse
              suivante&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-05-23_fr_0.pdf"
              >
                <span>
                  https://www.ramq.gouv.qc.ca/sites/default/files/documents/non_indexes/liste_med_2024-05-23_fr_0.pdf
                </span>
              </ExternalLinksModal>
              . <span className="font-bold">5. </span>Services aux Autochtones Canada. Services de santé non assurés;
              Direction générale de la santé des Premières Nations et des Inuits. Consulté en mars 2024 à l'adresse
              suivante&nbsp;:{" "}
              <ExternalLinksModal lang={lang} externalLink="https://nihb-ssna.express-scripts.ca/fr">
                <span>https://nihb-ssna.express-scripts.ca/fr</span>
              </ExternalLinksModal>
              . <span className="font-bold">6. </span>Anciens combattants Canada Couverture pour services, ordonnances
              et appareils. Consulté en avril 2024 à l'adresse suivante&nbsp;:{" "}
              <ExternalLinksModal
                lang={lang}
                externalLink="https://www.veterans.gc.ca/fr/programmes-et-services-financiers/frais-medicaux/couverture-pour-services-ordonnances-et-appareils"
              >
                <span>
                  https://www.veterans.gc.ca/fr/programmes-et-services-financiers/frais-medicaux/couverture-pour-services-ordonnances-et-appareils
                </span>
              </ExternalLinksModal>
              .
            </p>
          )}
        </div>

        <hr className="my-2 border-gray-400" />

        <div className="flex flex-col md:flex-row justify-between items-center py-5">
          <div>
            {lang != "CA-fr" ? (
              <p className="text-center md:text-left">
                Empaveli is a registered trademark of Swedish Orphan Biovitrum AB (publ).
                <br />© 2024 Sobi Canada, Inc. All rights reserved.
                <br />
                PP-23029 05/24
              </p>
            ) : (
              <p className="text-center md:text-left">
                Empaveli est une marque déposée de Swedish Orphan Biovitrum AB (publ).
                <br />© Sobi Canada, Inc., 2024. Tous droits réservés.
                <br />
                PP-23029F 05/24
              </p>
            )}
          </div>
          <div>
            <img src={SobiImage.src} alt="Sobi Logo" width={150} className="pt-2 md:pt-0" />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center">
          <div>
            <p></p>
          </div>
          <div>
            <div className="flex items-center">
              {lang != "CA-fr" ? (
                <a className="mr-0.5 underline" href="https://www.sobi.com/canada/en/privacy-policy-ca" target="_blank">
                  Privacy Policy
                </a>
              ) : (
                <a className="mr-0.5 underline" href="https://www.sobi.com/canada/en/privacy-policy-ca" target="_blank">
                  Politique de confidentialité
                </a>
              )}
              <Icon as="div" name="arrowTopRight" className="mr-2 w-4" />

              {lang != "CA-fr" ? (
                <a className="mr-0.5 underline" href="https://www.sobi.com/canada/en/terms-use-ca" target="_blank">
                  Terms of Use
                </a>
              ) : (
                <a className="mr-0.5 underline" href="https://www.sobi.com/canada/en/terms-use-ca" target="_blank">
                  Conditions générales d'utilisation
                </a>
              )}
              <Icon name="arrowTopRight" className="w-4" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
